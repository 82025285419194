.footer-wrapper {
  padding-top: 60px;
  background: #fff;
  p {
    color: #f5f5f5;
  }
}
.footer-wrapper-grey {
  background: #f5f5f5;
}
.footerMain {
  display: block;
  background-color: var(--black);
  color: #f5f5f5;
  padding: 91px 0 40px;
  font-size: 20px;
  max-width: 100%;
  margin: auto;
  margin-top: 4rem;
  position: relative;
  font-family: 'Inter';
  letter-spacing: -0.04em;
  &:before {
    content: ' ';
    position: absolute;
    display: block;
    width: 100%;
    height: 140px;
    background-image: url('https://earthly.org/img/subscriptionBg.png');
    background-size: contain;
    background-repeat: no-repeat;
    top: -140px;
    left: -100px;
  }
  @media screen and (max-width: 780px) {
    font-size: 14px;
    text-align: center;
    padding: 2rem;
    &:before {
      display: none;
    }
  }
}
.footerLogo {
  margin-bottom: 2rem;
}
.footerLinks {
  display: flex;
  margin-bottom: 8rem;
  nav {
    flex-basis: 160px;
    margin-right: 34px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 13px;
    color: #fff;
  }
  ul {
    li {
      margin-bottom: 13px;
      a {
        color: #959595;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
    margin-bottom: 2rem;
    nav {
      flex-basis: 100%;
      margin-right: 0;
      margin-top: 1rem;
      h4 {
        font-size: 15px;
      }
      ul {
        font-size: 15px;
        li {
          margin-bottom: 0;
          padding-bottom: 8px;
        }
      }
    }
  }
}

.footerUnder {
  display: flex;
  color: #959595;
  align-items: center;
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: -0.03em;
  p {
    margin: 0;
    font-size: 12px;
    line-height: 1.4;
    color: inherit;
  }
  a {
    color: #959595;
    margin-right: 20px;
    &:hover {
      color: #fff;
    }
  }
  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
}

.footerSocial {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0 0;
  a {
    margin-left: 1rem;
    color: #959595;
    &:hover {
      color: #fff;
    }
    svg {
      height: 30px;
      width: 30px;
    }
  }
}

.emailInput input:not([type='submit']) {
  font-size: 20px;
  width: 100%;
  line-height: 52px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.5px solid #f5f5f5;
  background-color: transparent;
  color: #fff;
}

.emailInput input::-webkit-input-placeholder {
  color: #f5f5f5;
  opacity: 1;
  font-weight: 400;
}
.emailInput input::-moz-placeholder {
  color: #f5f5f5;
  opacity: 1;
  font-weight: 400;
}
.emailInput input:-ms-input-placeholder {
  color: #f5f5f5;
  opacity: 1;
  font-weight: 400;
}
.emailInput input::-ms-input-placeholder {
  color: #f5f5f5;
  opacity: 1;
  font-weight: 400;
}
.emailInput input::placeholder {
  color: #f5f5f5;
  opacity: 1;
  font-weight: 400;
}
.emailInput input:-ms-input-placeholder {
  color: #f5f5f5;
  font-weight: 400;
}
.emailInput input::-ms-input-placeholder {
  color: #f5f5f5;
  font-weight: 400;
}

p.emailInput {
  position: relative;
  width: 100%;
  display: block;
}

p.emailInput input[type='submit'] {
  font-size: 0;
  background-image: url(https://earthly.org/wp-content/uploads/2020/09/Arrow-1.png);
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  width: 50px;
  background-color: transparent;
  position: absolute;
  right: -4px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footerMain .widget-title {
  color: #f5f5f5;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 40px;
}

.footerMain #footerSidebar5 .widget-title {
  display: none;
}

.footerMain ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.footerMain li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.footerMain .brow {
  margin-top: 32px;
  margin-bottom: 32px;
}

.footerMain #footerSidebar6 {
  font-size: 14px;
  color: #a6a6a6;
}

.footerMain #footerSidebar6 a {
  color: #a6a6a6;
}

.footerMain #footerSidebar5 ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footerMain #footerSidebar5 ul li a {
  height: 50px;
  width: 80px;
  font-size: 0;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.footerMain #footerSidebar5 .facebook a {
  background-image: url(https://earthly.org/wp-content/uploads/2020/09/icons_footer_facebook.png);
}

.footerMain #footerSidebar5 .linkedin a {
  background-image: url(https://earthly.org/wp-content/uploads/2020/09/icons_footer_linkedin.png);
}

.footerMain #footerSidebar5 .instagram a {
  background-image: url(https://earthly.org/wp-content/uploads/2020/09/icons_footer_instagram.png);
}

.footerMain #footerSidebar5 .twitter a {
  background-image: url(https://earthly.org/wp-content/uploads/2020/09/icons_footer_twitter.png);
}

.footerButton {
  max-width: 300px;
  padding-left: 2rem;
  text-align: right;
  h4 {
    font-size: 16px;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 780px) {
    h4 {
      font-size: 14px;
      margin-top: 2rem;
    }
    text-align: center;
    padding: 0;
    margin: auto;
    .footerSocial {
      justify-content: center;
    }
  }
}
