.payment-details {
  text-align: left;
  margin: 10px 0 0 40px;
  padding-bottom: 32px;
  border-bottom: 3px solid #F5F5F5;

  &-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &-payment-type {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    text-transform: uppercase;
  }

  &-no-payment {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
  }

  &-card {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #8D8D8D;
  }
}
