.profile-form {
  text-align: left;
  margin: 0 0 36px 40px;

  .profile-form-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    color: #2B271D;
    margin-bottom: 56px;
  }

  .profile-form-avatar {

    .avatar {
      cursor: pointer;
      position: relative;
      width: 154px;
      height: 154px;
      margin: 0 auto;
      border-radius: 154px;
      background: #2B271D url(../../icons/photo.svg) no-repeat 50% 50%;
      background-size: 36px;
    }

    &-loaded {
      .avatar {
        background-size: contain;
        background-position:center;
        background-repeat: no-repeat;
        border-radius: 0;
        border: none;
      }
    }

    .avatar-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      border: 2px solid #FFF;
      background: #D7D7D7 url(../../icons/pencil.svg) no-repeat 50% 50%;
      background-size: 20px;
      width: 46px;
      height: 46px;
      border-radius: 46px;
      z-index: 100;
    }

    .avatar-image-container {
      overflow: hidden;
      width: 154px;
      height: 154px;
      margin: 0 auto;
      border-radius: 154px;
    }

    .avatar-text {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      letter-spacing: -0.03em;
      color: #2B271D;
      margin: 23px 0 0;
    }

    .avatar-image {
      width: 100%;
      height: auto;
      position: relative;
      top: 50%;
      transform: translate(0%, -50%);
    }

    &:hover,
    &:focus {
      .avatar {
        background-color: #13110d;
      }

      .avatar-button {
        background-color: #b1b1b1;
      }
    }

    &-loaded:hover,
    &-loaded:focus {
      .avatar {
        background-color: transparent;
        border: 2px solid #FFF;
      }
    }
  }

  .avatar-input {
    display: none;
  }

  .profile-form-visibilty {
    display: block;
    margin: 44px auto;
    text-align: center;

    &-label {
      display: inline-block;
      padding-left: 32px;
      background: url(../../icons/globle.svg) no-repeat left center;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.03em;
      color: #2B271D;
    }

    &-switch {
      display: inline-block;
    }

    &-link {
      font-size: 14px;
    }
  }

  .profile-form-details {
    border-top: 3px solid #F5F5F5;
    padding: 32px 0;
  }

  .form-row {
    display: flex;
  }

  .profile-form-input {
    flex: 1;
  }

  .form-row {
    margin-bottom: 16px;

    .profile-form-input:first-child {
      margin-right: 24px;
    }
  }

  .form-row:first-child {
    .profile-form-input:first-child {
      margin-right: 0px;
    }
  }

  .change-password-wrapper {
    position: relative;
    flex: 1;

    .profile-form-input:first-child {
      display: block;
      margin: 0;
    }

    .MuiInputBase-formControl {
      display: block;
    }
  }

  .change-password {
    position: absolute;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: #2B271D;
    right: 8px;
    top: 4px;
  }

  .profile-help-text {
    position: relative;
    top: -12px;
    font-size: 14px;
  }
  
  .btn.account-page {
    height: auto;
    line-height: 22px;
    padding: 16px 12px;
    justify-content: center;
  }
}
