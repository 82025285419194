.dropdown {
  position: absolute !important;
  top: -120px;
  margin-right: 80px;

  @media screen and (min-width: 992px) {
    position: relative !important;
    top: 0;
    right: 0;
    margin-right: 0;
  }

  @media screen and (min-width: 1024px) {
    right: 0;
  }

  li {
    list-style: none;
  }

  li a,
  li a:hover,
  li a:focus {
    display: block;
    font-size: 1.125rem;
    letter-spacing: -0.04em;
    padding: 5px 0;
    color: #2b271d;
  }

  &-button {
    background: 0 none;
    padding: 0;
    border: 0 none;
    outline: 0 none;

    &:focus {
      border: 0 none;
      outline: 0 none;
    }
  }

  .submenu {
    position: relative;
    display: block;
    background: white;
    left: 10px;

    @media screen and (min-width: 1024px) {
      position: absolute;
      width: 100%;
      min-width: 240px;
      bottom: -190px;
      right: 0;
      left: auto;
      padding: 2rem 32px;
      box-shadow: -10px 15px 35px rgba(0, 0, 0, 0.15);
    }
  }
}

.dropdown-open {
  .submenu {
    display: block;
  }
}

.dropdown-close {
  .submenu {
    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

/* Hide Dropdowns by Default */
.navbar-container ul ul {
  width: 263px;
  right: 0;
  position: absolute;
  background: #fff;
  top: 40px;
  border: 1px solid #e8e8e8;
}

/* Fisrt Tier Dropdown */
.navbar-container ul ul li {
  float: none;
  display: list-item;
  position: relative;
}

.navbar-container .link-icon {
  padding-left: 52px;
  background: no-repeat 12px 50%;
}

.navbar-container .icon-profile {
  background-image: url(../../../icons/avatar.svg);
}

.navbar-container .icon-account {
  background-image: url(../../../icons/mdi_settings.svg);
}

.navbar-container .icon-logout {
  background-position: 20px 50%;
  background-image: url(../../../icons/logout.svg);
}

.navbar-container ul ul a:focus,
.navbar-container ul ul a:hover {
  background-color: #efefef;
  text-decoration: none;
  color: #2b271d;
}

.menu-toggle {
  position: relative;

  .avatar,
  .name {
    display: inline-block;
  }

  .avatar {
    position: absolute;
    top: -10px;
    left: 0;
  }

  .name {
    padding: 0 25px 0 52px;
    display: block;
    font-size: 20px;
    line-height: 50px;
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.03em;
    color: #2b271d;

    @media screen and (min-width: 1024px) {
      background: url(../../../icons/down-arrow.svg) no-repeat 100% 50%;
    }
  }
}
