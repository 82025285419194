.account-nav {
  background: #F5F5F5;
  border-radius: 5px;
  border: 0 none;
  padding: 45px 0;

  .account-nav-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .account-nav-link {
    display: block;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.04em;
    color: #747474;
    padding: 21px 24px;

    &:hover,
    &:focus {
      color: #2B271D;
      background: #EFEFEF;
    }
  }

  .account-nav-current {
    color: #2B271D;
    background: #EFEFEF;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &-top {

  }

  &-bottom {

  }
}
