@import '~bootstrap/scss/bootstrap.scss';
@import '~animate.css/animate.css';

:root {
  --gutter: 1rem;
  --earthly-green: #00db8f;
  --earthly-green-50: #80edc7;
  --earthly-green-20: #ccf8e9;
  --jungle: #007d50;
  --jungle-50: #80bea8;
  --jungle-20: #cce5dc;
  --reef: #564991;
  --reef-50: #9d96ba;
  --reef-20: #dddbe9;
  --waves: #41b4bc;
  --waves-50: #a0d9dd;
  --waves-20: #d9f0f2;
  --sea: #0550c0;
  --sea-50: #82a8e0;
  --sea-20: #cddcf2;
  --sea-10: #e6eef9;
  --blossom: #dc78ae;
  --sand: #c59031;
  --sun: #e88430;
  --sun-20: #fae6d6;
  --clay: #8a1a11;
  --primary: var(--earthly-green);
  --primary-lighten: #79eac3;
  --secondary: var(--jungle);
  --light-bg: #f7f3ea;
  --light-green-bg: #c1e2cc;
  --violet-bg: #dae5fa;
  --purple-bg: #f5dff2;
  --white: #fff;
  --black: #000;
  --pastel-coral: #ffe4cc;
  --pastel-aqua: #d1f0ef;
  --success: #35d28b;
  --orange: var(--sun);
  --red: var(--clay);
  --light-blue: #1391d2;
  --grey: #979797;
  --light-grey: #dbdbdb;
  --dark-grey: #293339;
  --alternative-grey: #959595;
  --light-white: #f2f2f2;
  --dark-blue: #001489;
  --max-row-width: 1430px;
  --mobile-header-height: 58px;
  --desktop-header-height: 80px;
  --scrollbar-width: 16px;
}

@font-face {
  font-family: 'Druk';
  src: url('./fonts/Druk-Medium.woff2') format('woff2'), url('./fonts/Druk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*
@font-face {
  font-family: 'Inter';
  src: url(./fonts/GT-Cinetype-Bold.otf);
}

@font-face {
  font-family: 'CinetypeReg';
  src: url(./fonts/GT-Cinetype-Regular.otf);
}
*/
body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  letter-spacing: -0.03em;
  color: #2b271d;
  line-height: 140%;
}
a {
  color: var(--earthly-green);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px;
  }
}

.home-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #2b271d;
  margin-bottom: 24px;
}

.country-selection-trees {
  background: url(./icons/tree-banner.svg) repeat-x 0% 140%;
}

.country-selection-flag {
  position: relative;
  background: url(./icons/flag.svg) no-repeat 90% 110%;
}

.country-selection-grey {
  background: transparent url(./icons/bckgr_light-grey.svg) repeat-x 0% 642px;
  background-size: cover;
  padding-bottom: 182px;

  @media screen and (min-width: 780px) {
    background-position: 0% 512px;
  }
}

.project-selection-grey {
  background: transparent url(./icons/bckgr_light-grey.svg) repeat-x 50% 0%;
  background-size: cover;
  clear: both;
}

.project-selection-list {
  background: #f5f5f5;
}

.country-selection-form {
  background: #f5f5f5;
  padding-top: 72px 0 36px;
}

.project-selection-list {
  padding-bottom: 128px;
}

.section-profile-logo {
  background: #f5f5f5;
  padding: 64px 0;
}

.section-profile-impact {
  background: #f5f5f5;
  padding: 0 0 100px;
}

.section-profile-overview {
  background: #f5f5f5 url(./icons/impact-white-background.svg) 50% 0%;
  background-size: cover;
  padding-top: 48px;

  &-bg {
    background: #fff;
  }
}

.section-profile-support {
  position: relative;
  background: transparent url(./icons/impact-dark-background.svg) 50% 0%;
  background-size: cover;
  padding-top: 48px;
  padding-bottom: 20px;
  top: -48px;

  &-bg {
    background: #2b271d;
  }
}

.section-profile-history {
  position: relative;
  padding-top: 48px;
}

.section-profile-comparison {
  position: relative;
  background: transparent url(./icons/impact-background-grey.svg) 50% 0%;
  padding-top: 48px;
  padding-bottom: 48px;
  top: -80px;

  @media screen and (min-width: 780px) {
    padding-bottom: 48px;
  }

  &-bg {
    background: #f5f5f5;
  }
}

@media screen and (max-width: 780px) {
  .account-page {
    display: flex;

    .account-left-col {
      width: 44.8%;
    }

    .account-right-col {
      width: 55.2%;
    }
  }
}

.subscription-modifier {
  text-align: left;
  padding-bottom: 42px;
  border-bottom: 3px solid #f5f5f5;
  margin-bottom: 42px;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 120px;
  }

  &-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &-subtext {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    margin-bottom: 24px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #2b271d;
  }
  a {
    color: #2b271d;
  }
  a:hover {
    text-decoration: none;
  }

  .btn.subscription-page {
    height: auto;
    line-height: 22px;
    padding: 16px 12px;
    justify-content: center;
  }
}

.profile-form-visibilty-switch-checked .MuiSwitch-track {
  background: #6ce49c !important;
}

.profile-header {
  margin: 0 auto;
}

.auth-selector {
  margin-bottom: 120px;

  .page-title,
  .page-title h2 {
    text-align: left;
  }
}

.bkg-gray {
  background: #f7f7f7;
}

.section-introduction {
  padding: 6rem 0 10rem;
  position: relative;
  h1 {
    position: relative;
    
  }
  .introduction-img {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    max-width: none;
    flex: 0;
    width: 800px;
    //position: relative;
    img {
      //max-width: 400px;
      width: 100%;
    }
  }

  @media screen and (max-width: 1260px) {
    .introduction-img {
      right: 2rem;
      bottom: 2rem;
      width: 600px;
    }
  }
  @media screen and (max-width: 780px) {
    padding-top: 3rem;
    padding-bottom: 10rem;
    .introduction-img {
      width: 300px;
    }
    .row {
      button {
        margin-top: 0;
      }
    }
  }
  @media screen and (max-width: 560px) {
    .introduction-img {
      width: 260px;
      right: 1rem;
      bottom: 1rem;
    }
  }
}
@media screen and (max-width: 780px) {
  .page-title h2,
  h2 {
    font-size: 60px;
  }
}
.section-home-faq {
  padding: 8rem 2rem;
  font-family: 'Inter';
  letter-spacing: -0.03em;
  font-weight: 400;
  p {
    font-family: 'Inter';
    letter-spacing: -0.03em;
    font-weight: 400;
  }
  .faqs-title {
    font-size: 62px;
  }
  .faqs-section-title {
    display: none;
  }
  .faqs-question {
    font-family: 'Inter';
    font-size: 26px;
    letter-spacing: -0.04em;
    font-weight: normal;
  }
  @media screen and (max-width: 780px) {
    padding: 3rem 0;
    .faqs-title {
      font-size: 42px;
      margin-bottom: 2rem;
    }
    .faqs-question {
      font-size: 20px;
    }
  }
}

.section-home-country {
  .country-selector-extended .country-selector-title {
    font-size: 34px;

    font-family: 'Inter';
  }
  .country-selector-subtitle {
    display: none;
  }
  .country-selector-form-title {
    font-size: 20px;
    font-family: 'Inter';
    margin-bottom: 20px;
  }
  @media screen and (max-width: 780px) {
    padding: 1rem;
    .row {
      justify-content: center;
    }
    .country-selector-extended .country-selector-title {
      line-height: 120%;
      font-size: 26px;
    }
  }
}

.page .btn {
  padding: 12px 32px;
  display: inline-flex;
  text-decoration: none;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  background: var(--primary);
  color: var(--black);
  font-size: 1.375rem;
  line-height: 1;
  margin-top: 2rem;
  height: auto;
  box-shadow: none;
  font-family: inherit;
  span {
    font-weight: normal;
  }
  &:hover,
  &:focus {
    background: #6de49d;
  }
}
.pt-8 {
  padding-top: 8rem;
  @media screen and (max-width: 780px) {
    padding-top: 4em;
  }
}
.pb-8 {
  padding-bottom: 8em;
  @media screen and (max-width: 780px) {
    padding-bottom: 4em;
  }
}
.pt-10 {
  padding-top: 10rem;
}
.pb-10 {
  padding-bottom: 10rem;
}

h2.carbon {
  max-width: 640px;
  margin: auto;
  margin-top: 2rem;
  font-size: 24px;
  line-height: 130%;
  font-weight: normal;
  @media screen and (max-width: 780px) {
    font-size: 18px;
    padding: 0 1rem;
  }
}

.client-logos-wrapper {
  h2 {
    font-size: 1.8rem;
    margin-bottom: 28px;
    color: var(--primary);
    letter-spacing: -0.04em;
  }
  img {
    width: 100%;
    &.img-client-logos-mobile {
      display: none;
    }
  }
  @media screen and (max-width: 780px) {
    h2 {
      text-align: center;
      font-size: 1.4rem;
    }
    img {
      padding: 2rem;

      &.img-client-logos {
        display: none;
      }
      &.img-client-logos-mobile {
        display: block;
      }
    }
  }
}

.profile-image-failed {
  width: 100%;
  height: 100%;
  background: url(/icons/avatar-placeholder.png) no-repeat 50% 50%;
  background-size: contain;
}
.profile-title-name {
  width: 100%;
  margin-top: 2rem;
  font-family: 'Druk';
}
