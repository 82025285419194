.profile-overview {
  display: block;
  width: 100%;
  padding: 36px 16px 56px 16px;

  @media screen and (min-width: 780px) {
    padding: 36px 0 56px 0;
  }

  &-title {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 95px;
    line-height: 100%;
    text-align: center;
    color: #2b271d;
  }

  &-intro {
    max-width: 700px;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2b271d;
  }

  &-island {
    height: 360px;
    background: url(../../icons/3d-island@2x.png) no-repeat center -80px;
    background-size: 640px 608px;
    margin: 0 0 0;
    z-index: 1;

    @media screen and (min-width: 780px) {
      height: 780px;
      background-size: 1580px 1258px;
      background-position: center -120px;
    }
  }
}
