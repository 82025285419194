.api-key {
  text-align: left;
  margin: 34px 0 0 40px;

  &-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &-alert {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
  }

  &-items {
    width: 100%;
    background: #F5F5F5;
    padding: 24px;
  }

  &-item-header {
    font-family: 'Inter';
    padding-bottom: 20px;
    border-bottom: 3px #FFF solid;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #626262;
  }

  &-item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #2B271D;
  }

  &-item-date {
    width: 25%;
  }

  &-item-border {
    display: block;
    margin: 9px 0;
    padding: 11px 10px;
    border-right: 3px solid #FFF;
  }

  &-item-button {
    border: 0 none;
    outline: 0 none;
    background: transparent;

    &:focus,
    &:hover {
      outline: 0 none;
    }
  }

  &-item-title {
    width: 70%;
    padding: 20px 0 20px 36px;
  }

  &-item-download {
    width: 10%;
    padding: 20px 0;
    text-align: center;
  }
}
