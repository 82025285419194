.login-form-input {
  margin: 12px 0 !important;
}

.login-form-forgotten {
  margin: 6px 0;
  padding: 6px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  text-align: left;
  text-decoration: underline;
  letter-spacing: -0.04em;
  color: #2B271D;
}

.login-form-btn {
  display: block !important;
  margin: 12px auto 24px !important;
  /*width: 207px;*/
  height: 56px;
}

.login-form-register {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.03em;
  color: #8D8D8D;
}

.login-form-register a {
  font-weight: bold;
  color: #2B271D;
}

.login-form {
  .MuiInputBase-formControl,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-radius: 2px;
  }

  .MuiInputBase-formControl {
    background: #F5F5F5;
  }
}
