.page .btn {
  height: 56px;
  background: #6ce49c;
  border-radius: 100px;
  font-family: 'Inter';
  font-style: normal;
  font-size: 20px;
  line-height: 200%;
  letter-spacing: -0.03em;
  color: #000000;
  text-transform: none;
}


.page .btn.Mui-disabled {
  background: #6ce49c;
  color: rgba(0, 0, 0, 0.5);
}

.page .btn:focus,
.page .btn:hover {
  background: #5bc184;
}

.page--profile {
  background: #f5f5f5;
}

.page--home-landing {
  background: #e1f2ef;
}

.Header_main__2XU7S + main {
  padding-top: var(--mobile-header-height);
  background-color: var(--white);
}

@media screen and (min-width: 1024px) {
  .Header_main__2XU7S + main {
    padding-top: var(--desktop-header-height);
  }
}
