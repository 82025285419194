.profile-impact {
  width: 100%;
  background: #fff;
  padding: 28px;

  @media screen and (min-width: 992px) {
    display: flex;
  }

  &-item {
    position: relative;
    padding: 24px 0;
    border-bottom: 3px #f5f5f5 solid;

    &:last-child {
      border-bottom: 0;
    }

    @media screen and (min-width: 992px) {
      flex: 1 1 0;
      width: 33%;
      padding-top: 0;
      border-bottom: 0 none;
    }
  }

  .icon {
    top: 0;
    left: auto;
    right: auto;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: 992px) {
      position: absolute;
    }
  }

  .icon-trees {
    background-image: url(../../icons/impact-tree.svg);
    background-size: 323px 183px;
    height: 183px;

    @media screen and (min-width: 992px) {
      width: 323px;
      top: -125px;
      left: 0;
    }
  }

  .icon-carbon {
    background-image: url(../../icons/impact-carbon.svg);
    background-size: 366px 128px;
    height: 128px;

    @media screen and (min-width: 992px) {
      width: 366px;
      top: -75px;
      left: -20px;
    }
  }

  .icon-forest {
    background-image: url(../../icons/impact-forest.svg);
    background-size: 307px 196px;
    height: 196px;

    @media screen and (min-width: 992px) {
      width: 307px;
      top: -120px;
      left: 32px;
    }
  }
  &.profile-impact-has-no-trees {
    .icon-carbon {
      @media screen and (min-width: 992px) {
        left: 50%;
        margin-left: -200px;
      }
    }
    .icon-forest {
      @media screen and (min-width: 992px) {
        left: 50%;
        margin-left: -150px;
      }
    }
  }
  &.profile-impact-has-no-land {
    .icon-carbon {
      @media screen and (min-width: 992px) {
        left: 50%;
        margin-left: -200px;
      }
    }
    .icon-trees {
      @media screen and (min-width: 992px) {
        left: 50%;
        margin-left: -200px;
      }
    }
  }
  &-item-value {
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #2b271d;
    margin: 16px 0 8px;

    @media screen and (min-width: 992px) {
      margin: 106px 0 8px;
    }
  }

  &-item-label {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2b271d;
  }

  &-divider {
    display: none;
    position: relative;
    width: 3px;
    height: 180px;
    margin: 17px 0;
    background: #f5f5f5;

    @media screen and (min-width: 992px) {
      display: block;
    }
  }
}
