.welcome-form {

  &-input {
    margin: 12px 0 !important;
  }

  &-btn {
    display: block !important;
    margin: 12px auto 24px !important;
    width: auto;
    height: 56px;
  }

  &-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: -0.03em;
    margin-bottom: 44px;
  }

  &-link {
    font-family: 'Inter';
    color: #000000;
  }

  .MuiInputBase-formControl,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border-radius: 2px;
  }

  .MuiInputBase-formControl {
    background: #F5F5F5;
  }
  .login-redirect a {
    font-weight: bold;
    color: #2B271D;
  }
}
