.project-basket {
  -webkit-position: sticky;
  position: sticky;
  top: 64px;
  text-align: left;
  background: #fff;
}

.project-basket-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #000000;
  padding: 15px 24px;
  /*border-bottom: 10px solid #f5f5f5;*/
}

.project-basket-team {
  padding: 24px 24px 0;

  &-header {
    display: flex;
  }

  &-title {
    flex: 1 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #676767;
  }

  &-unit {
    flex: 1 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #676767;
    text-align: right;
  }

  &-line {
    margin-bottom: 18px;

    &:last-child {
      border-bottom: 1px solid #dadada;
      margin-bottom: 0;
      padding-bottom: 18px;
    }

    @media screen and (min-width: 780px) {
      display: flex;
    }
  }

  &-label,
  &-value {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 26px;
    color: #2b271d;

    @media screen and (min-width: 780px) {
      flex: 1;
    }
  }

  &-label {
    @media screen and (min-width: 780px) {
      flex: 1;
      min-width: 180px;
    }
  }

  &-value {
    @media screen and (min-width: 780px) {
      text-align: right;
    }
  }
}

.project-basket-total {
  &-line {
    padding: 12px 24px;
    display: flex;
    /*border-bottom: 10px solid #f5f5f5;*/
  }

  &-label,
  &-value {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #2b271d;
    flex: 1;
  }

  &-label {
    font-family: 'Inter';
    font-weight: bold;
    flex: 1;
  }

  &-value {
    text-align: right;
  }
}

.project-basket-project-name {
  margin-bottom: 0;
}

.project-basket-project {
  padding: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: #676767;
  /*border-bottom: 10px solid #f5f5f5;*/
}

.project-basket-no-project {
  padding-bottom: 24px;
}

.project-basket-project-cost {
  display: flex;
  padding: 12px 0 0;

  &-label,
  &-value {
    flex: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: #2b271d;
  }

  &-label {
    flex: 1;
    font-family: 'Inter';
    font-weight: bold;
  }

  &-value {
    text-align: right;
  }
}

.project-basket-cost {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  padding: 18px 24px;

  &-label,
  &-value {
    flex: 1;
  }

  &-label {
    flex: 2;
  }

  &-value {
    text-align: right;
  }
}

.project-basket-submit {
  padding: 18px 24px;

  .btn {
    .page & {
      display: block;
      width: 100%;
    }
  }
}
