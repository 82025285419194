.payment-form {
  width: 100%;
  margin: 0 0 128px;
  max-width: 680px;

  .form-title {
    font-family: 'Inter';
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 36px;
  }

  .form-row {
    margin: 0 0 16px;
  }

  .form-field {
    width: 100%;

    @media screen and (min-width: 780px) {
      flex: 1 1;
    }

    &:first-child {
      margin-bottom: 16px;

      @media screen and (min-width: 780px) {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }
  }

  .payment-form-input {
    width: 100%;
    display: block;
  }

  .MuiFilledInput-input {
    font-size: 16px;
  }

  .btn-payment {
    .page & {
      margin: 0 auto;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
