.profile-edit {
  padding-top: 20px;

  &-link {
    background: url(../../icons/pencil.svg) no-repeat 0% 50%;
    background-size: 20px;
    padding-left: 32px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2B271D;
  }
}
