.employee-total {
    padding: 22px;
    background: #F5F5F5;
    margin-bottom: 18px;
}

.employee-total-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #000000;
   
}
 @media screen and (max-width: 780px) {
    .employee-total-title {
        font-size: 15px;
    }
    .employee-total .row{
        align-items: center;
        justify-content: center;
    }
    .employee-total .row .col-sm{
        width: auto;
    }
 }

.employee-total .employee-total-value {
    font-family: 'Inter';
    text-align: right;
    padding-right: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #000000;
}