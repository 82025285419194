.profile-breakdown {
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 120px;
  padding: 0 16px;

  @media screen and (min-width: 780px) {
    padding: 0;
  }

  &-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: -0.03em;
  
  }

  &-table {
    background: #fbfbfb;
    border: 3px solid #eaeaea; 
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    overflow-y: auto;
    height: 700px;

    table {
      max-width: 100%;
    }
  }

  &-data-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #626262;
    padding: 20px 0;
    border-bottom: 3px solid #eaeaea;

    &-value {
      padding-left: 12px;
    }
  }

  &-data-item {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #2b271d;
  }

  &-data-value {
    display: block;
    padding: 24px 0 24px 12px;
    font-family: 'Inter';
    color: #2b271d;
    border-left: 3px solid #f5f5f5;
  }

  &-data-row:first-child {
    .profile-breakdown-data-value {
      margin-top: 16px;
      padding-top: 8px;
    }
  }
}
