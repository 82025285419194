.change-password {
  text-align: left;
  margin: 0 0 36px 40px;

  .change-password-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    color: #2B271D;
    margin-bottom: 56px;
  }

  .form-row {
    display: flex;
    margin-bottom: 16px;
  }

  .change-password-input {
    flex: 1;
  }

  .btn-row .btn {
    width: 200px;
  }

  .btn-row {
    text-align: right;
  }
}
