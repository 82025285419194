
.faqs {
    &-title {
        font-family: Druk;
        font-style: normal;
        font-weight: 500;
        font-size: 95px;
        line-height: 100%;
        text-align: center;
        color: #000000;
        margin-bottom: 64px;
    }

    &-section-title {
        font-family: Druk;
        font-style: normal;
        font-weight: 500;
        font-size: 58px;
        line-height: 100%;
        color: #2B271D;
        text-align: left;
        margin-bottom: 18px;
    }

    &-content {
        text-align: left;
    }

    &-question {
        font-family: 'Inter';
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 140%;
        align-items: center;
        letter-spacing: -0.03em;
        color: #2B271D;
    }
}

.faq-section {
    padding: 120px 0 54px;
    margin-top: 36px;
    background: url(../../icons/bckgr_white.svg) 50% 0%;
    background-size: cover;
}
