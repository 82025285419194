.registration-form {
  width: 100%;
  margin: 0 16px 128px;
  max-width: 680px;

  .form-title {
    font-family: 'Inter';
    text-align: left;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 36px;
  }

  .form-row {
    margin-bottom: 16px;

    &-terms {
      margin-bottom: 0;
    }
  }

  .form-field {
    width: 100%;

    @media screen and (min-width: 780px) {
      flex: 1 1;
    }

    &:first-child {
      margin-bottom: 16px;

      @media screen and (min-width: 780px) {
        margin-right: 24px;
        margin-bottom: 0;
      }
    }
  }

  .registration-form-input,
  .country-selector-select,
  .MuiInputBase-formControl {
    width: 100%;
    display: block;
  }

  .MuiFilledInput-input {
    width: 93%;
  }

  .country-selector-select {
    max-width: none;

    .MuiFilledInput-input {
      margin-right: 0;
      width: 100%;
      padding: 27px 0 10px;
    }

    .MuiInputLabel-formControl {
      z-index: 1;
      transform: translate(12px, 20px) scale(1);
      pointer-events: none;
    }

    .MuiInputLabel-formControl.MuiInputLabel-shrink {
      transform: translate(12px, 10px) scale(0.75);
    }
  }

  .form-terms {
    color: #8d8d8d;
  }

  .form-terms-link {
    font-family: 'Inter';
    color: #000000;
  }

  .registration-form-btn {
    .page & {
      margin: 0 auto;
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
