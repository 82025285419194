
.avatar-small {
  width: 40px;
  height: 40px;
}

.avatar-medium {
  width: 152px;
  height: 152px;
}
