.profile-support {
  display: block;
  width: 100%;
  margin-bottom: 48px;
  padding: 0 16px;

  @media screen and (min-width: 780px) {
    padding: 0;
  }

  &-title {
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 100%;
    color: #f5f5f5;
    margin: 100px 0 48px;
    text-align: left;

    @media screen and (min-width: 780px) {
      font-size: 95px;
    }
  }

  &-projects {
    display: flex;
    flex-wrap: wrap;
  }
}

.project-support {
  max-width: 545px;
  flex: 1 0 50%;
  margin-bottom: 32px;

  &:first-child {
    margin-right: 20px;
  }

  &-display {
    position: relative;
    height: 300px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    max-width: 100%;
  }

  &-image-failed {
    width: 545px;
    height: 300px;
    background: #6d6d6d;
  }

  &-title {
    position: relative;
    z-index: 9999;
    padding: 80px 24px 0 24px;
    font-family: Druk;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 52px;
    line-height: 100%;
    color: #ffffff;
    text-align: left;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 780px) {
      font-size: 64px;
    }
  }

  &-link {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: right;
    letter-spacing: -0.03em;
    color: #ffffff;
    background: url(../../icons/project-arrow.svg) 100% 0% no-repeat;
    padding-right: 42px;

    &:hover,
    &:focus {
      color: #ffffff;
    }
  }
}
