.progress-step {
  padding: 0;
  position: relative;
  display: block;
  float: left;
  width: 25%;
  text-align: center;

  .progress-steps--purchase & {
    width: 33.333333%;
  }
}
.progress-img-wrapper{
  flex: 1;
  width: 100%;
  min-width: 100%;
  img{
    max-width: 100%;
  }
  @media screen and (max-width: 780px) {
    img{
      max-width: 260px;
    }
  }
}
.progress-step-circle {
  display: block;
  margin: 0 auto 8px;
  width: 32px;
  text-align: center;
  border: 2px solid #e4e4e4;
  color: #e4e4e4;
  font-family: Druk;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-radius: 32px;
  line-height: 28px;
}

.progress-step-title {
  font-size: 9px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #757575;
  max-width: 80px;
  margin: 0 auto;
}

.progress-step-bar-left {
  position: absolute;
  top: 15px;
  height: 3px;
  right: -25px;
  max-width: 56px;
  width: 56px;
  border-radius: 10px 0 0 10px;
  background-color: #e4e4e4;
}

.progress-step-bar-right {
  position: absolute;
  top: 15px;
  height: 3px;
  left: -25px;
  max-width: 56px;
  width: 56px;
  border-radius: 0 10px 10px 0;
  background-color: #e4e4e4;
}

.progress-step:first-child .progress-step-bar-right,
.progress-step:last-child .progress-step-bar-left {
  display: none;
}

.progress-step-active .progress-step-circle,
.progress-step-done .progress-step-circle {
  border-color: #6ce49c;
  color: #6ce49c;
}

.progress-step-active .progress-step-bar-left,
.progress-step-next .progress-step-bar-right,
.progress-step-active .progress-step-bar-right,
.progress-step-done .progress-step-bar-left,
.progress-step-done .progress-step-bar-right {
  background-color: #6ce49c;
}

.progress-step-done .progress-step-circle {
  background: #6ce49c url(../../icons/tick.svg) no-repeat center center;
  text-indent: -9999px;
}
