*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
header,
nav {
  display: block;
}
[tabindex="-1"]:focus {
  outline: none !important;
}
h4 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
ul {
  margin-bottom: 1rem;
}
ul {
  margin-top: 0;
}
ul ul {
  margin-bottom: 0;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
svg:not(:root) {
  overflow: hidden;
}
[role="button"],
a,
button {
  touch-action: manipulation;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button,
html [type="button"] {
  -webkit-appearance: button;
}
[type="button"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
button {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
p {
  margin: 0;
  padding: 0;
  color: var(--black);
  font-size: 1.125rem;
  letter-spacing: -0.04em;
  font-weight: 300;
}
@media (max-width: 767px) {
  p {
    font-size: 1rem;
  }
}
a {
  color: var(--black);
  text-decoration: underline;
}
h4 {
  font-family: Inter, sans-serif;
  margin: 0;
  padding: 0;
  color: var(--black);
}
button {
  margin: 0;
}
.icon {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
  -moz-box-align: center;
  align-items: center;
}
/*! CSS Used from: https://staging.earthly.org/_next/static/css/f8c74473e3d0a226885d.css */
.Grid_container__1ghah {
  width: 100%;
  --half-column: 100%/12;
  --half-column-mobile: 100%/4;
}
.Grid_row__3bzwQ {
  display: -moz-box;
  display: flex;
  max-width: 1430px;
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 767px) {
  .Grid_container__1ghah {
    --row-padding: 0;
    --column-gutter: -webkit-calc(var(--gutter) * (1));
    --column-gutter: calc(var(--gutter) * (1));
    --column: -webkit-calc(100% / 4);
    --column: calc(100% / 4);
  }
  .Grid_row__3bzwQ {
    padding: 0 var(--row-padding);
  }
}
@media (min-width: 768px) and (max-width: 1239px) {
  .Grid_container__1ghah {
    --column-gutter: -webkit-calc(var(--gutter) * (2.2));
    --column-gutter: calc(var(--gutter) * (2.2));
    --row-padding: 0;
    --column: 100%/12;
  }
  .Grid_row__3bzwQ {
    padding: 0 var(--row-padding);
  }
}
@media (min-width: 1240px) and (max-width: 1599px) {
  .Grid_container__1ghah {
    --column-gutter: -webkit-calc(var(--gutter) * (2.2));
    --column-gutter: calc(var(--gutter) * (2.2));
    --row-padding: 0;
    --column: 100%/12;
  }
  .Grid_row__3bzwQ {
    padding: 0 var(--row-padding);
  }
}
@media (min-width: 1600px) {
  .Grid_container__1ghah {
    --column-gutter: -webkit-calc(var(--gutter) / (2));
    --column-gutter: calc(var(--gutter) / (2));
    --row-padding: 0;
    --column: 100%/12;
  }
  .Grid_row__3bzwQ {
    padding: 0 var(--row-padding);
  }
}
.Logo_logo__1UxF6 {
  visibility: visible;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 1023px) {
  .Logo_logo__1UxF6 {
    max-width: 60px;
  }
}
.Navigation_menu__2WvVH {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 1024px) {
  .Navigation_menu__2WvVH {
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
  }
}
.Navigation_menu__Item__24O80 {
  position: relative;
}
.Navigation_menu__Link__1FwJS {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  background: transparent;
  border: none;
  font-size: 1.625rem;
  line-height: 0.95;
  font-weight: 400;
  color: var(--black);
  letter-spacing: -0.04em;
  text-decoration: none;
  padding: 1.06rem 1.375rem;
  margin: 0;
  white-space: nowrap;
  font-family: Inter;
}
.Navigation_menu__Link__1FwJS > span,
.Navigation_menu__Link__1FwJS svg {
  pointer-events: none;
}
.Navigation_menu__Link__1FwJS .Navigation_menu__LinkTextDesktop__1Sodh {
  color: var(--black);
}
.Navigation_menu__Link__1FwJS:hover {
  text-decoration: none;
  color: var(--black);
}
.Navigation_menu__Link__1FwJS:hover .Navigation_menu__LinkTextDesktop__1Sodh {
  color: inherit;
}
@media screen and (min-width: 1024px) {
  .Navigation_menu__Link__1FwJS {
    font-size: 1.125rem;
  }
}
.Navigation_menu__LinkIcon__267K_ {
  display: none;
}
@media screen and (min-width: 1024px) {
  .Navigation_menu__LinkIcon__267K_ {
    display: block;
    margin-right: 10px;
  }
}
.Navigation_menu__LinkLabel__1tz5E {
  margin-left: 8px;
  font-size: 0.625rem;
  padding: 3.5px 9px;
  display: block;
}
.Navigation_submenu__1ophF {
  display: block;
  list-style: none;
  padding: 0 0 0 calc(var(--column-gutter) + (14px));
  margin: 0;
}
@media screen and (min-width: 1024px) {
  .Navigation_submenu__1ophF {
    padding: 0;
  }
}
.Navigation_submenu__1ophF li {
  margin: 0;
}
.Navigation_submenu__1ophF li:not(:first-of-type) {
  margin-top: 12px;
}
.Navigation_submenu__1ophF li a {
  font-size: 1.125rem;
  letter-spacing: -0.04em;
  padding: 5px 0;
}
.Navigation_submenuTrigger__22BMS {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
}
.Navigation_submenuTrigger__22BMS > span,
.Navigation_submenuTrigger__22BMS svg {
  pointer-events: none;
}
.Navigation_submenuTrigger__22BMS:focus {
  outline: none;
}
@media screen and (min-width: 1024px) {
  .Navigation_submenuTrigger__22BMS svg {
    margin-left: 8px;
  }
  .Navigation_submenuTrigger__22BMS:hover .icon-plus {
    display: none;
  }
}
.Navigation_submenuContainer__3HLWY {
  display: none;
}
@media screen and (min-width: 1024px) {
  .Navigation_submenuContainer__3HLWY {
    position: absolute;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    pointer-events: none;
    opacity: 0;
    height: 0;
    top: 100%;
    left: auto;
    margin-left: -20px;
    display: block;
    padding-top: 2rem;
  }
  .Navigation_submenuContainer__3HLWY {
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
}
@media screen and (max-width: 1023px) {
  .Navigation_submenuWrapper__gqr0o {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 1024px) {
  .Navigation_submenuWrapper__gqr0o {
    background: var(--white);
    -webkit-box-shadow: -10px 15px 35px rgba(0, 0, 0, 0.15);
    box-shadow: -10px 15px 35px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 2rem 43px 55px 37px;
    width: 392px;
  }
}
.Navigation_submenuContent__3TrVH h4,
.Navigation_submenuContent__3TrVH p {
  display: none;
}
@media screen and (min-width: 1024px) {
  .Navigation_submenuContent__3TrVH {
    margin-bottom: 38px;
  }
  .Navigation_submenuContent__3TrVH h4,
  .Navigation_submenuContent__3TrVH p {
    display: block;
  }
  .Navigation_submenuContent__3TrVH h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 42px;
  }
  .Navigation_submenuContent__3TrVH p {
    font-size: 1.125rem;
    line-height: 24px;
    margin: 16px 0 0;
  }
}
@media screen and (max-width: 1023px) {
  .Navigation_overviewLink__R2lg9 {
    display: block;
    text-decoration: none;
    line-height: 0.95;
    font-weight: 400;
    color: var(--black);
    font-size: 1.125rem;
    letter-spacing: -0.04em;
    margin-bottom: 12px;
    padding: 5px 0 5px calc(var(--column-gutter) + (14px));
  }
}
.Navigation_overviewLink__R2lg9 span {
  display: none;
}
@media screen and (min-width: 1024px) {
  .Navigation_overviewLink__R2lg9 {
    display: -moz-box;
    display: flex;
    margin: 10px 0 0;
    font-size: 1.125rem;
  }
  .Navigation_overviewLink__R2lg9 span {
    display: block;
  }
  .Navigation_overviewLink__R2lg9:focus,
  .Navigation_overviewLink__R2lg9:hover {
    color: var(--black);
  }
  .Navigation_overviewLink__R2lg9 span {
    margin-left: 5px;
  }
  .Navigation_topMenu__m4vwP:hover .Navigation_submenuContainer__3HLWY {
    pointer-events: auto;
    opacity: 1;
    height: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .Navigation_topMenu__m4vwP:hover .Navigation_submenuTrigger__22BMS {
    text-decoration: none;
    color: var(--black);
  }
  .Navigation_topMenu__m4vwP:hover
    .Navigation_submenuTrigger__22BMS
    .icon-plus {
    display: none;
  }
}
.Navigation_mobileNavigation__1d5Rc {
  width: 100%;
}
@media screen and (max-width: 1023px) {
  .Navigation_mobileNavigation__1d5Rc {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-height: -webkit-calc(100vh - 58px);
    min-height: calc(100vh - 58px);
    max-height: -webkit-fill-available;
    overflow-y: scroll;
    background-color: var(--white);
    padding-top: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .Navigation_mobileNavigation__1d5Rc {
    display: -moz-box;
    display: flex;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    flex-direction: row;
    -moz-box-pack: justify;
    justify-content: flex-end;
  }
}

.Navigation_mobileNavigationOpen__3qxv1 {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -moz-box-pack: justify;
  justify-content: space-between
}

.Navigation_mobileMenuToggle__3yjKh {
  display: block;
  width: 29px;
  height: 28px;
  padding: 0;
  align-self: flex-end;
  border: none;
  background-color: transparent;
  position: relative;
}
.Navigation_mobileMenuToggle__3yjKh:focus {
  outline: none;
}
.Navigation_mobileMenuToggle__3yjKh span {
  display: none;
  visibility: hidden;
}
.Navigation_mobileMenuToggle__3yjKh:after,
.Navigation_mobileMenuToggle__3yjKh:before {
  display: block;
  position: absolute;
  content: "";
  width: 25px;
  height: 2px;
  left: 2px;
  top: 0;
  background-color: #000;
  -webkit-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  will-change: transform;
}
.Navigation_mobileMenuToggle__3yjKh:before {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.Navigation_mobileMenuToggle__3yjKh:after {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}
@media screen and (min-width: 1024px) {
  .Navigation_mobileMenuToggle__3yjKh {
    display: none;
  }
}
.Navigation_mobileMenuToggleOpen__1wxdJ:after,.Navigation_mobileMenuToggleOpen__1wxdJ:before {
  -webkit-transform-origin: center center;
  transform-origin: center center
}

.Navigation_mobileMenuToggleOpen__1wxdJ:before {
  -webkit-transform: translateY(14px) rotate(45deg);
  transform: translateY(14px) rotate(45deg)
}

.Navigation_mobileMenuToggleOpen__1wxdJ:after {
  -webkit-transform: translateY(14px) rotate(-45deg);
  transform: translateY(14px) rotate(-45deg)
}

.Navigation_bottomLinks__xXMF0 {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  padding: 15px 25px;
  position: sticky;
  bottom: 0;
  background: var(--white);
  z-index: 2;
}
@media screen and (min-width: 1024px) {
  .Navigation_bottomLinks__xXMF0 {
    padding: 0;
    -moz-box-orient: horizontal;
    -moz-box-direction: reverse;
    flex-direction: row-reverse;
    background-color: transparent;
    -moz-box-align: center;
    align-items: center;
  }
}
.Navigation_bottomLinks__PrimaryAction__1ikV-,
.Navigation_bottomLinks__SecondaryAction__1oGO8 {
  text-align: center;
  text-decoration: none;
}
.Navigation_bottomLinks__PrimaryAction__1ikV-:focus,
.Navigation_bottomLinks__PrimaryAction__1ikV-:hover,
.Navigation_bottomLinks__SecondaryAction__1oGO8:focus,
.Navigation_bottomLinks__SecondaryAction__1oGO8:hover {
  text-decoration: none;
}
.Navigation_bottomLinks__PrimaryAction__1ikV- {
  line-height: 48px;
  font-size: 1.5rem;
  font-weight: 400;
  border-radius: 24px;
}
.Navigation_bottomLinks__PrimaryAction__1ikV-,
.Navigation_bottomLinks__PrimaryAction__1ikV-:hover {
  background-color: var(--black);
  color: var(--white);
}
@media screen and (min-width: 1024px) {
  .Navigation_bottomLinks__PrimaryAction__1ikV- {
    font-size: 1.125rem;
    background-color: var(--primary);
    border-radius: 6px;
    color: var(--black);
    padding: 14px 24px;
    line-height: 1;
  }
}
.Navigation_bottomLinks__SecondaryAction__1oGO8 {
  margin-top: 15px;
  font-size: 1.125rem;
  color: var(--black);
}
.Navigation_bottomLinks__SecondaryAction__1oGO8:hover {
  color: var(--black);
}
@media screen and (min-width: 1024px) {
  .Navigation_bottomLinks__SecondaryAction__1oGO8 {
    margin: 0 15px 0 0;
  }
}
.Header_main__2XU7S {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  max-width: 100%;
  background-color: var(--white);
}
@media screen and (min-width: 768px) {
  .Header_main__2XU7S {
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(6px);
  }
}
.Header_row__1gYR2 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
@media screen and (max-width: 1023px) {
  .Header_row__1gYR2 {
    -moz-box-pack: justify;
    justify-content: space-between;
  }
}
.Header_row__1gYR2 > div {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
  justify-content: center;
}
.Header_logoWrapper__3edp8,
.Header_navWrapper__1FhaG {
  padding: 0 var(--column-gutter);
}
@media screen and (min-width: 1024px) {
  .Header_navWrapper__1FhaG {
    -moz-box-flex: 1;
    flex-grow: 1;
  }
}
.Header_showFixedNav__3i80_ {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZs.woff)
    format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZs.woff)
    format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZs.woff)
    format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
