.project-item {
  position: relative;
  text-align: left;
  background: #f5f5f5;
  margin-bottom: 36px;
  border: 1px solid #fff;
  border-radius: 15px;
  .MuiPaper-root {
    background: transparent !important;
  }
  &:hover,
  &:focus {
    border: 1px solid #6ce49c;
  }

  &-active {
    border: 1px solid #6ce49c;
  }

  &-image {
    max-width: 100%;
    margin-bottom: 24px;
  }

  &-top {
    width: 100%;
    padding: 24px;

    @media screen and (min-width: 780px) {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: nowrap;
    }
  }

  &-image-container {
    @media screen and (min-width: 780px) {
      flex: 1 100%;
      max-width: 208px;
      margin-right: 12px;
    }
  }

  &-image-failed {
    background: #e8e8e8;
    height: 200px;
    width: 200px;
  }

  &-details {
    @media screen and (min-width: 780px) {
      flex: 4;
      min-width: 338px;
      flex-shrink: 0;
    }
  }

  &-selected {
    position: absolute;
    top: 12px;
    right: 12px;
    text-align: right;

    @media screen and (min-width: 780px) {
      position: relative;
      top: auto;
      right: auto;
      flex: 1 100%;
      max-width: 58px;
    }
  }

  .MuiAccordion-root {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-top: 2px solid #f5f5f5;
  }

  &-show-more {
    font-family: 'Inter';
    display: block;
    text-align: center;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;

    text-align: center;
    letter-spacing: -0.03em;
    color: #2b271d;
  }

  &-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
    letter-spacing: -0.03em;
    color: #333333;
    margin-bottom: 8px;
  }

  &-details {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: #2b271d;
    margin-bottom: 8px;
  }

  &-tags {
    margin-bottom: 16px;
  }

  &-tag {
    display: inline-block;
    padding: 3px 16px;
    border-radius: 20px;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: -0.03em;
    color: #2b271d;
    background: #e2faeb;
  }

  &-description {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #575757;
    span {
      font-family: inherit !important;
    }
  }

  &-sdgs {
    &-count {
      padding: 0 6px 0 32px;
      background: url('../../icons/sdg.png') no-repeat 10px 2px;
      background-size: 16px 16px;
    }
  }
}

.MuiAccordionDetails-root {
  flex-direction: column;
}

.project-sdgs-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .project-sdgs-item {
    padding: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    text-align: left;
    color: #2b271d;
    width: 99px;
    height: 99px;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 99px 99px;
  }

  .project-sdgs-item:last-child {
    margin-right: 0;
  }

  .project-sdgs-item-title {
    text-indent: -9999px;
    top: -9999;
    left: -9999;
    position: absolute;
  }

  .no-poverty {
    background-image: url(../../icons/sdgs/1.png);
  }

  .zero-hunger {
    background-image: url(../../icons/sdgs/2.png);
  }

  .good-health-and-well-being {
    background-image: url(../../icons/sdgs/3.png);
  }

  .quality-education {
    background-image: url(../../icons/sdgs/4.png);
  }

  .gender-equality {
    background-image: url(../../icons/sdgs/5.png);
  }

  .clean-water-and-sanitation {
    background-image: url(../../icons/sdgs/6.png);
  }

  .affordable-and-clean-energy {
    background-image: url(../../icons/sdgs/7.png);
  }

  .decent-work {
    background-image: url(../../icons/sdgs/8.png);
  }

  .industry-innovation-and-infrastructure {
    background-image: url(../../icons/sdgs/9.png);
  }

  .reduces-inequalities {
    background-image: url(../../icons/sdgs/10.png);
  }

  .sustainable-cities-and-communities {
    background-image: url(../../icons/sdgs/11.png);
  }

  .responsible-consumption-and-production {
    background-image: url(../../icons/sdgs/12.png);
  }

  .climate-action {
    background-image: url(../../icons/sdgs/13.png);
  }

  .life-below-water {
    background-image: url(../../icons/sdgs/14.png);
  }

  .life-on-land {
    background-image: url(../../icons/sdgs/15.png);
  }

  .peace-justice-and-strong-institutions {
    background-image: url(../../icons/sdgs/16.png);
  }

  .partnerships-for-the-goals {
    background-image: url(../../icons/sdgs/17.png);
  }
}

@media screen and (max-width: 780px) {
  .project-item-name {
    font-size: 22px;
  }
  .project-item-description {
    font-size: 1rem;
  }
  .project-item-image-container {
    max-height: 200px;
    overflow: hidden;
    margin-bottom: 1rem;

    img {
      object-fit: cover;
    }
  }
}
