.page-title,
.page-title h2 {
  margin-top: 24px;
  margin-bottom: 24px;
  font-family: Druk;
  font-style: normal;
  font-weight: 500;
  font-size: 90px;
  line-height: 100%;
  text-align: center;
  color: #2b271d;
}
.page-subtitle {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Druk;
  font-style: normal;
  font-weight: 500;
  font-size: 65px;
  line-height: 100%;
  text-align: center;
  color: #2b271d;
}

.page-title-trees h2 {
  margin-bottom: 85px;
}

.page-title-onboarding h2 {
  margin: 40px 0 26px;
}

.page-title-register {
  margin-top: 0;
}

.page-title-register h2 {
  margin-top: 64px;
}

.page-title-projects {
  margin: 0;
}

.page-title-projects h2 {
  padding: 76px 0 0;
  margin-bottom: 54px;
}

.page-title-carbon {
  width: 100%;
}

.page-title-carbon h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #2b271d;
  max-width: 450px;
  margin: 0 auto;
}

.page-title-payment h2 {
  margin-bottom: 74px;
}

@media screen and (min-width: 780px) {
  .page-title-payment h2 {
    text-align: left;
  }
}

@media screen and (max-width: 780px) {
  .page-title-projects h2 {
    padding: 2rem 0 0;
  }
}

.page-title-congratulations {
  margin-bottom: 84px;
}

.page-title-landing h2 {
  font-size: 57px;
  line-height: 60px;
}

@media screen and (min-width: 780px) {
  .page-title-landing h2 {
    font-size: 165px;
    line-height: 168px;
  }
}
