.account-header {
  min-height: 280px;
  background: url(../../icons/account-header.svg) repeat-x 50% 0%;
  background-size: cover;
  margin-bottom: 36px;

  h1 {
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 95px;
    line-height: 100%;
    color: #2B271D;
    text-align: center;
    padding: 63px 0 82px;
  }
}
