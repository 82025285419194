.order-summary {
  border: 3px solid #f5f5f5;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  max-width: 525px;
  margin: 0 auto 40px;

  @media screen and (min-width: 780px) {
    background: url(../../icons/summary.svg) no-repeat 90% 100px;
  }
}

.order-summary-title {
  font-family: 'Inter';
  font-size: 30px;
  line-height: 140%;
  color: #000000;
  padding: 25px 30px;
}

.order-summary-product {
  border-bottom: 10px solid #f5f5f5;

  &-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: #626262;
    padding: 0 30px;
  }

  &-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    color: #000;
    padding: 0 30px 60px;
  }
}

.order-summary-team {
  padding: 25px 30px;

  table {
    width: 100%;
  }

  th {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    align-items: center;
    text-align: right;
    color: #2b271d;
  }

  &-title {
    font-family: 'Inter';
    font-size: 30px;
    line-height: 140%;
    color: #000000;
  }

  &-line {
    margin-bottom: 18px;
  }

  &-label,
  &-value,
  &-cost {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 26px;
    color: #2b271d;
  }

  &-label {
    font-family: 'Inter';
    font-weight: normal;
    padding: 16px 0;

    @media screen and (min-width: 780px) {
      min-width: 200px;
    }
  }

  &-value,
  &-cost {
    text-align: right;

    @media screen and (min-width: 780px) {
      min-width: 100px;
    }
  }
}

.order-summary-total {
  &-line {
    padding: 12px 24px;
  }

  &-line {
    margin-bottom: 18px;
  }

  &-label,
  &-value,
  &-cost {
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    height: 26px;
    color: #2b271d;
    border-top: 3px solid #f5f5f5;
  }

  &-label {
    @media screen and (min-width: 780px) {
      min-width: 200px;
    }
    padding: 16px 0;
  }

  &-value,
  &-cost {
    @media screen and (min-width: 780px) {
      min-width: 100px;
    }
    text-align: right;
  }
}

.order-summary-project {
  padding: 25px 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 100%;
  color: #676767;
  border-top: 10px solid #f5f5f5;

  &-title {
    font-family: 'Inter';
    font-size: 30px;
    line-height: 140%;
    color: #000000;
  }

  &-value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
    color: #2b271d;
  }
}

.order-summary-no-project {
  padding-bottom: 24px;
}

.order-summary-project-cost {
  display: flex;

  &-label,
  &-value {
    flex: 1;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #2b271d;
  }

  &-label {
    flex: 2;
    font-family: 'Inter';
    font-weight: bold;
  }

  &-value {
    text-align: right;
  }
}

.order-summary-cost {
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 100%;
  padding: 25px 30px;
  border-top: 10px solid #f5f5f5;

  &-label,
  &-value {
    flex: 1;
  }

  &-label {
    flex: 2;
  }

  &-value {
    text-align: right;
  }
}

.order-summary-submit {
  padding: 18px 24px;

  .btn {
    .page & {
      display: block;
      width: 100%;
    }
  }
}
