.api-documentation {
  text-align: left;
  margin: 34px 0 0 40px;

  &-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 22;
    padding-bottom: 19px;
    border-bottom: 3px solid #F5F5F5;
  }

  &-text {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #2B271D;
  }

  &-link {
    display: block;
    padding: 12px 24px;
    background: #F5F5F5 url(../../icons/link.svg) 95.5% 50% no-repeat;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #2B271D;

    &:hover,
    &:focus {
      color: #2B271D;
      background-color: #cecece;
      text-decoration: none;
    }
  }
}
