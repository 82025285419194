.country-selector {
  width: 100%;
  padding: 54px 0 36px;

  &-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 140%;
    text-align: center;
    margin-bottom: 12px;
  }

  &-subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
  }

  &-select {
    width: 100%;

    .MuiSelect-selectMenu {
      padding: 12px 0;
    }

    @media screen and (min-width: 780px) {
      max-width: 320px;
    }
  }

  .btn {
    display: block;
    margin: 18px auto 18px;
  }
}

.country-selector-single {
  .country-selector-title {
    letter-spacing: -0.03em;
    color: #2b271d;
  }

  .country-selector-subtitle {
    letter-spacing: -0.03em;
    color: #2b271d;
  }
}

.country-selector-extended {
  .country-selector-title {
    font-family: Druk;
    font-weight: 400;
    font-size: 76px;
    line-height: 40px;
    max-width: 18ch;
    text-align: center;
    margin-bottom: 32px;
  }

  .country-selector-subtitle {
    font-family: 'Inter';
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 57px;
  }

  .country-selector-form-title {
    font-family: 'Inter';
    font-size: 37px;
    line-height: 46px;
    margin-bottom: 42px;
  }
}
