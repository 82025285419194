.payment-history {
  text-align: left;
  margin: 34px 0 0 40px;

  &-header {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &-alert {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
  }

  &-items {
    width: 100%;
  }

  &-item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.03em;
    color: #2B271D;
    border-bottom: 1px solid #E3E3E3;
  }

  &-item-date {
    width: 40%;
    padding: 20px 0;
  }

  &-item-title {
    width: 50%;
    padding: 20px 0;
  }

  &-item-download {
    width: 10%;
    padding: 20px 0;
  }
}
