.progress-steps {
    display: block;
    width: 100%;
    position: relative;
    margin: 40px 0;
    max-width: 492px;
}

.progress-steps--purchase {
    max-width: 420px;
}

.progress-steps:after {
    content: "";
    display: table;
    clear: both;
}

