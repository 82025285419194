.employee-selector {
  margin: 36px 0 18px;
  background: #f8f8f8;

  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #6ce49c;
    box-shadow: 0px 2px 8px rgba(108, 228, 156, 0.3);
  }
}

.employee-selector-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  padding: 18px;
}

.employee-selector-select {
  width: 172px;
  height: 56px;

  .Mui-focused .MuiOutlinedInput-notchedOutline,
  &:focus .MuiOutlinedInput-notchedOutline,
  &:hover .MuiOutlinedInput-notchedOutline {
    border: 2px solid #6ce49c;
    box-shadow: 0px 2px 8px rgba(108, 228, 156, 0.3);
  }
}

.employee-selector .MuiFormHelperText-root {
  text-align: center;
}

.employee-selector .MuiSelect-outlined {
  border-radius: 8px;
}

.employee-selector .MuiInputLabel-formControl {
  top: -2px;
  left: 12px;
}

.employee-selector .MuiInputLabel-shrink {
  top: -8px;
}

.employee-selector .MuiInputBase-root {
  border-radius: 8px;
}

// .employee-selector .MuiFormLabel-root.Mui-focused,
.employee-selector
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline,
.employee-selector
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.employee-selector
  .MuiOutlinedInput-root:focus
  .MuiOutlinedInput-notchedOutline {
  border: 2px solid #6ce49c;
}

.employee-selector-subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.04em; 
  color: #2b271d;
  margin: 36px 0 28px;
}

.employee-selector-points {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 48px;
}

.employee-selector-point {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  color: #2b271d;
  padding: 8px 40px 8px 40px;
  background: no-repeat 18px 14px;
}

.employee-selector-average .employee-selector-header {
  background: rgba(0, 125, 80, 0.5);
}

.employee-selector-medium .employee-selector-header {
  background: rgba(65, 180, 188, 0.5);
}

.employee-selector-high .employee-selector-header {
  background:  #5a5a5a;
  color: white;
}

.employee-selector-average .employee-selector-point {
 // background-image: url(../../icons/average.svg);
}

.employee-selector-medium .employee-selector-point {
 // background-image: url(../../icons/medium.svg);
}

.employee-selector-high .employee-selector-point {
 // background-image: url(../../icons/high.svg);
}
