.profile-comparison {
  display: block;
  width: 100%;

  &-title {
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 95px;
    line-height: 100%;
    text-align: center;
    color: #000000;
    margin: 60px 0 24px;
  }

  &-subtitle {
    max-width: 700px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2b271d;
    margin: 0 auto 80px;
  }

  &-list {
    width: 100%;

    @media screen and (min-width: 780px) {
      display: flex;
    }
  }

  &-item {
    margin: 0 16px;
    background: #fff no-repeat 50% 100%;

    @media screen and (min-width: 780px) {
      margin: 0 20px 0 0;
      flex: 1 1 0;
    }
  }

  &-item-value {
    font-family: Druk;
    font-style: normal;
    font-weight: 500;
    font-size: 120px;
    line-height: 100%;
    text-align: center;
    color: #2b271d;
    padding: 58px 0 16px;

    @media screen and (min-width: 780px) {
      padding: 0;
      margin: 58px 0 16px;
    }
  }

  &-item-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #333333;
    padding-bottom: 200px;

    @media screen and (min-width: 780px) {
      padding-bottom: 270px;
    }
  }

  &-item-car {
    background-image: url(../../icons/impact-car.svg);
  }

  &-item-burger {
    background-image: url(../../icons/impact-burger.svg);
    background-position: 0% 87%;
  }

  &-item-flights {
    background-image: url(../../icons/impact-flights.svg);
  }
}
