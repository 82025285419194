.carbon-footprint {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #2B271D;
}

.carbon-footprint-country {
    position: relative;
    top: -2px;
    text-indent: -9999px;
    width: 24px;
    height: 24px;
    display: inline-block;
}

$countries: 'AE', 'US', 'HK', 'CA', 'CH', 'DE', 'NO', 'DK', 'NL', 'GB', 'IT', 'SE', 'FR', 'ES', 'GR', 'CN', 'PT';

@each $country in $countries {
    .carbon-footprint-#{$country} {
        background: url('../../icons/flags/#{$country}.svg');
    }
}