.profile-image {
  overflow: hidden;
  width: inherit;
  height: inherit;
  margin: 0 auto;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  &-image {
    width: 100%;
    height: auto;
    position: relative;
    top: 50%;
    transform: translate(0%, -50%);
  }
}
